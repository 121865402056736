import { createSlice } from '@reduxjs/toolkit';
import {
  getProduct,
  getVouchersProduct,
  getUserPhotos,
  getPeopleAlsoBuyProducts,
  getTrendingNowProducts,
  getRecentlyViewed,
  getProductReviews,
  getTotalReviews,
  getTotalReviewsWithPhoto,
  getTotalVerifiedReviews,
  reviewLike,
  addWishlist,
  notifyMe,
  getSimiliarProducts,
  reportReview,
  requestToDeleteReview,
  getTotalUserPhotos,
  loadMoreProductReviews,
  getProductDetail,
  getEgiftTemplates,
} from '~store/product-detail/thunks';
import { isWeb } from '~global/screens';

const reviewParams = {
  'user.skin_types.name': {
    name: 'All Skin Type',
    value: 'All Skin Type',
    isSelected: true,
    filterName: 'user.skin_types.name',
  },
  average_rating: {
    name: 'All Ratings',
    isSelected: true,
    filterName: 'average_rating',
  },
  'user.birthday_date': {
    name: 'All Age group',
    value: 'All Age group',
    isSelected: true,
    filterName: 'user.birthday_date',
  },
  sort: {
    name: 'Newest',
    isSelected: true,
    filterName: 'sort',
  },
  review_type: {
    name: 'All Review',
    isSelected: true,
    value: null,
    filterName: 'All Review',
  },
  mostHelpfulActived: {
    filterName: 'mostHelpfulActived',
    value: false,
  },
  'product.combination.id': {
    filterName: 'product.combination.id',
    value: [],
  },
};

export const { reducer } = createSlice({
  name: 'productDetail',
  initialState: {
    product: '',
    vouchers: '',
    tabViewName: 'description',
    userPhotos: '',
    peopleAlsoBuy: '',
    recentlyViewed: '',
    trendingNow: '',
    reviews: '',
    reviewsFiltered: '',
    totalReviews: '',
    totalReviewsWithPhoto: '',
    totalVerifiedReviews: '',
    defaultCombination: '',
    similiarProducts: [],
    productDetailReview: '',
    reportReview: '',
    totalUserPhotos: 0,
    isFiltered: false,
    qtyProduct: 1,
    reviewParams,
    egift: {
      step: 1,
      selectedImage: '',
      selectedCombinationId: '',
      selectedCombinationPrice: null,
      selectedCombinationPriceKey: -1,
      formFilled: false,
      payload: {
        recipient_phone_no: '',
        recipient_email: '',
        message: '',
        delivery_date: '',
        recipient_name: '',
        sender_name: '',
        is_confirm: true,
      },
      buttonChooseTemplateTheme: 'primary',
      buttonUploadPhotoTheme: 'light-primary',
      designType: 'template',
      photoUploaded: null,
      messageTemplates: [],
      photoUploadedAfterAddWatermark: null,
      finalPhoto: null,
      tempMessageTemplates: [],
    },
    status: {
      product: 'idle',
      vouchers: 'idle',
      userPhotos: 'idle',
      peopleAlsoBuy: 'idle',
      recentlyViewed: 'idle',
      trendingNow: 'idle',
      reviews: 'idle',
      totalReviews: 'idle',
      reviewLike: 'idle',
      addWishlist: 'idle',
      notifyMe: 'idle',
      similiarProducts: 'idle',
      reportReview: 'idle',
      requestToDeleteReview: 'idle',
      totalUserPhotos: 'idle',
      loadMoreReviews: 'idle',
      productDetailReview: 'idle',
      totalReviewsWithPhoto: 'idle',
      totalVerifiedReviews: 'idle',
      messageTemplates: 'idle',
    },
  },
  reducers: {
    setTabView(state, { data }) {
      state.tabViewName = data;
    },
    incrementQtyProduct(state) {
      state.qtyProduct += 1;
    },
    decrementQtyProduct(state) {
      if (state.qtyProduct === 1) {
        return;
      }
      state.qtyProduct -= 1;
    },
    resetEgiftPayload(state) {
      state.egift.payload = {
        recipient_phone_no: '',
        recipient_email: '',
        message: '',
        delivery_date: '',
        recipient_name: '',
        sender_name: '',
        is_confirm: true,
      };
    },
    resetEgiftState(state) {
      state.defaultCombination = JSON.parse(
        JSON.stringify(state.product.default_combination),
      );

      state.egift = {
        step: 1,
        selectedImage: '',
        selectedCombinationId: '',
        selectedCombinationPrice: null,
        selectedCombinationPriceKey: -1,
        formFilled: false,
        payload: {
          recipient_phone_no: '',
          recipient_email: '',
          message: '',
          delivery_date: '',
          recipient_name: '',
          sender_name: '',
          is_confirm: true,
        },
        buttonChooseTemplateTheme: 'primary',
        buttonUploadPhotoTheme: 'light-primary',
        designType: 'template',
        photoUploaded: null,
        messageTemplates: [],
        photoUploadedAfterAddWatermark: null,
        finalPhoto: null,
      };
    },
    setState(state, { name, value }) {
      state[name] = value;
    },
    setStatusApi(state, { name, value }) {
      state.status[name] = value;
    },
    setIsFiltered(state, { value }) {
      state.isFiltered = value;
    },
    setListCombinations(state, { data }) {
      state.product.listCombinations = data;
    },
    toggleReviewLike(state, { key }) {
      state.reviews[key].has_liked = !state.reviews[key].has_liked;
      if (state.reviews[key].has_liked) {
        state.reviews[key].total_likes++;
      } else {
        state.reviews[key].total_likes--;
      }
    },
    toggleWislisht(state, { productCombinationId }) {
      const combinations = state.product.combinations || [];
      if (state.defaultCombination['_id'] === productCombinationId) {
        state.defaultCombination.is_wishlist =
          !state.defaultCombination.is_wishlist;
      }

      if (state.product.default_combination?._id === productCombinationId) {
        state.product.default_combination.is_wishlist =
          !state.product.default_combination.is_wishlist;
      }

      combinations.map((combination, key) => {
        if (combination['_id'] === productCombinationId) {
          state.product.combinations[key].is_wishlist =
            !state.product.combinations[key].is_wishlist;
        }
      });

      if (
        state.product.listGroupAttribute &&
        Object.entries(state.product.listGroupAttribute).length
      ) {
        Object.entries(state.product.listGroupAttribute).map(
          ([groupId, combination], key) => {
            if (combination['_id'] === productCombinationId) {
              state.product.listGroupAttribute[groupId].is_wishlist =
                !state.product.listGroupAttribute[groupId].is_wishlist;
            }
          },
        );
      }
    },
    resetState(state) {
      state.product = '';
      state.vouchers = '';
      state.tabViewName = '';
      state.userPhotos = '';
      state.peopleAlsoBuy = '';
      state.trendingNow = '';
      state.reviews = '';
      state.defaultCombination = '';
      state.productDetailReview = '';
      state.similiarProducts = [];
      state.reviewsFiltered = '';
      state.totalUserPhotos = 0;
      state.qtyProduct = 1;
      state.reviewParams = reviewParams;
      state.egift = {
        step: 1,
        selectedImage: '',
        selectedCombinationId: '',
        selectedCombinationPrice: null,
        selectedCombinationPriceKey: -1,
        formFilled: false,
        payload: {
          recipient_phone_no: '',
          recipient_email: '',
          message: '',
          delivery_date: '',
          recipient_name: '',
          sender_name: '',
          is_confirm: true,
        },
        buttonChooseTemplateTheme: 'primary',
        buttonUploadPhotoTheme: 'light-primary',
        designType: 'template',
        photoUploaded: null,
        messageTemplates: [],
        photoUploadedAfterAddWatermark: null,
        finalPhoto: null,
      };

      Object.keys(state.status).map((name) => {
        state.status[name] = 'idle';
      });
    },
    setButtonChooseTemplateTheme(state, { value }) {
      state.egift.buttonChooseTemplateTheme = value;
    },
    setButtonUploadPhotoTheme(state, { value }) {
      state.egift.buttonUploadPhotoTheme = value;
    },
    setEgiftDesignType(state, { value }) {
      state.egift.designType = value;
    },
    setPhotoUploaded(state, { value }) {
      state.egift.photoUploaded = value;
    },
    incrementStepOnEgiftForm(state) {
      state.egift.step = state.egift.step + 1;
    },
    decrementStepOnEgiftForm(state) {
      state.egift.step = state.egift.step - 1;
    },
    setEgiftPayloadMessage(state, { message }) {
      state.egift.payload.message = message;
    },
    setPhotoUploadedAfterAddWatermark(state, { value }) {
      state.egift.photoUploadedAfterAddWatermark = value;
    },
    setFinalPhoto(state, { value }) {
      state.egift.finalPhoto = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProduct.rejected, (state) => {
        state.status.product = 'failed';
      })
      .addCase(getProduct.pending, (state) => {
        state.status.product = 'loading';
      })
      .addCase(getProduct.fulfilled, (state, { payload }) => {
        let defaultCombinationInStock = null;
        const { default_combination } = payload.data;
        const combinations = payload.data.combinations || [];
        const isNotPackDetail =
          Array.isArray(payload.data.pack_detail) &&
          !payload.data.pack_detail.length;
        const isDefaultCombinationOutOfStock =
          !default_combination.is_in_stock &&
          default_combination.is_out_of_stock_lilla;

        if (isNotPackDetail) {
          const combinations = [];
          let is_contains_shade = false;
          combinations.map((combination, key) => {
            Object.entries(combination.attributes).map(
              ([attributeName, attribute]) => {
                payload.data.combinations[key].attributes[
                  attributeName
                ].isDisabled = false;
              },
            );
          });

          if (payload.data.combinations && payload.data.combinations.length) {
            payload.data.combinations.forEach((combination, key) => {
              if (
                combination.attributes &&
                Object.keys(combination.attributes).length &&
                combination.is_active_in_review
              ) {
                let combination_group_name_html = '';
                const combination_group_shade = {};
                let combinationName = '';

                for (const attribute in combination.attributes) {
                  let attribute_name;
                  // eslint-disable-next-line no-prototype-builtins
                  if (combination.attributes.hasOwnProperty(attribute)) {
                    if (
                      attribute !== 'non_specify' &&
                      (combination.attributes[attribute].my_sociolla_sql_id ||
                        combination.attributes[attribute].my_soco_sql_id)
                    ) {
                      combination_group_name_html = combination_group_name_html
                        ? combination_group_name_html + ', '
                        : combination_group_name_html;
                      combinationName = combinationName
                        ? combinationName + ' - '
                        : combinationName;
                      if (
                        combination.attributes[attribute].value !== null &&
                        combination.attributes[attribute].value.search('#') !==
                          -1 &&
                        combination.attributes[attribute].value.length <= 7
                      ) {
                        combination_group_shade.hex =
                          combination.attributes[attribute].value;
                      } else {
                        combination_group_shade.url =
                          combination.attributes[attribute].value;
                      }
                      attribute_name = attribute.replace(
                        /(?:^|\s)\S/g,
                        function (a) {
                          return a.toUpperCase();
                        },
                      );
                      combination_group_name_html += `${attribute_name} - ${combination.attributes[attribute].name}`;
                      combinationName += combination.attributes[attribute].name;
                      if (attribute === 'shade') {
                        combination_group_shade.name = attribute.replace(
                          /(?:^|\s)\S/g,
                          function (a) {
                            return a.toUpperCase();
                          },
                        );
                        is_contains_shade = true;
                      }
                    }
                  }
                }

                if (combination_group_name_html) {
                  let name_html = combination_group_name_html;
                  const explode_combination_to_array = name_html.split(',');

                  if (
                    explode_combination_to_array.length > 1 &&
                    explode_combination_to_array[1].indexOf('shade')
                  ) {
                    name_html =
                      explode_combination_to_array[1] +
                      ', ' +
                      explode_combination_to_array[0];
                  }

                  combinations.push({
                    shade: combination_group_shade,
                    name: combinationName,
                    variant: name_html.trim(),
                    images: combination.images,
                    is_contains_shade,
                    _id: combination['_id'],
                    is_active_in_review: combination.is_active_in_review,
                    is_reviewed: combination.is_reviewed,
                    is_verified_purchase: combination.is_verified_purchase,
                  });
                }
              }
            });
            payload.data.listCombinations = combinations;
          }
        }

        if (isDefaultCombinationOutOfStock && isNotPackDetail) {
          // eslint-disable-next-line no-unused-vars
          let hasSavedProductInStock = false;
          combinations.map((combination, key) => {
            if (!combination.is_out_of_stock_lilla) {
              hasSavedProductInStock = true;
              defaultCombinationInStock = combination;
            }
          });
          payload.data.default_combination = defaultCombinationInStock;
        }
        state.totalReviews = payload.data?.review_stats?.total_reviews;
        state.product = payload.data;
        state.defaultCombination = defaultCombinationInStock
          ? defaultCombinationInStock
          : default_combination;
        state.status.product = 'succeeded';
      })
      .addCase(getVouchersProduct.pending, (state) => {
        state.status.vouchers = 'pending';
      })
      .addCase(getVouchersProduct.fulfilled, (state, { payload }) => {
        state.vouchers = payload.data;
        state.status.vouchers = 'succeeded';
      })
      .addCase(getUserPhotos.rejected, (state) => {
        state.status.userPhotos = 'error';
      })
      .addCase(getUserPhotos.pending, (state) => {
        state.status.userPhotos = 'pending';
      })
      .addCase(getUserPhotos.fulfilled, (state, { payload }) => {
        state.userPhotos = payload.data;
        state.status.userPhotos = 'succeeded';
      })
      .addCase(getRecentlyViewed.pending, (state) => {
        state.status.recentlyViewed = 'pending';
      })
      .addCase(getRecentlyViewed.fulfilled, (state, { payload }) => {
        state.recentlyViewed = payload.data;
        state.status.recentlyViewed = 'succeeded';
      })
      .addCase(getPeopleAlsoBuyProducts.pending, (state) => {
        state.status.peopleAlsoBuy = 'pending';
      })
      .addCase(getPeopleAlsoBuyProducts.fulfilled, (state, { payload }) => {
        state.peopleAlsoBuy = payload.data;
        state.status.peopleAlsoBuy = 'succeeded';
      })
      .addCase(getTrendingNowProducts.pending, (state) => {
        state.status.trendingNow = 'pending';
      })
      .addCase(getTrendingNowProducts.fulfilled, (state, { payload }) => {
        state.trendingNow = payload.data;
        state.status.trendingNow = 'succeeded';
      })
      .addCase(getProductReviews.rejected, (state) => {
        state.status.reviews = 'error';
      })
      .addCase(getProductReviews.pending, (state) => {
        state.status.reviews = 'pending';
      })
      .addCase(getProductReviews.fulfilled, (state, { payload }) => {
        const data = payload.data || [];
        data.map((review, key) => {
          payload.data[key].indexArray = key;
        });
        state.reviews = payload.data;
        state.status.reviews = 'succeeded';
      })
      .addCase(loadMoreProductReviews.rejected, (state) => {
        state.status.loadMoreReviews = 'error';
      })
      .addCase(loadMoreProductReviews.pending, (state) => {
        state.status.loadMoreReviews = 'pending';
      })
      .addCase(loadMoreProductReviews.fulfilled, (state, { payload }) => {
        const data = payload.data || [];
        data.map((review, key) => {
          payload.data[key].indexArray = key;
        });

        if (isWeb) {
          state.reviews = payload.data;
        } else {
          state.reviews = [...state.reviews, ...payload.data];
        }

        state.status.loadMoreReviews = 'succeeded';
      })
      .addCase(getTotalReviews.pending, (state) => {
        state.status.totalReviews = 'pending';
      })
      .addCase(getTotalReviews.fulfilled, (state, { payload }) => {
        state.totalReviews = payload.data;
        state.status.totalReviews = 'succeeded';
      })
      .addCase(getTotalReviewsWithPhoto.pending, (state) => {
        state.status.totalReviewsWithPhoto = 'pending';
      })
      .addCase(getTotalReviewsWithPhoto.fulfilled, (state, { payload }) => {
        state.totalReviewsWithPhoto = payload.data;
        state.status.totalReviewsWithPhoto = 'succeeded';
      })
      .addCase(getTotalVerifiedReviews.pending, (state) => {
        state.status.totalVerifiedReviews = 'pending';
      })
      .addCase(getTotalVerifiedReviews.fulfilled, (state, { payload }) => {
        state.totalVerifiedReviews = payload.data;
        state.status.totalVerifiedReviews = 'succeeded';
      })
      .addCase(reviewLike.pending, (state) => {
        state.status.reviewLike = 'pending';
      })
      .addCase(reviewLike.fulfilled, (state, { payload }) => {
        state.status.reviewLike = 'succeeded';
      })
      .addCase(addWishlist.rejected, (state) => {
        state.status.addWishlist = 'error';
      })
      .addCase(addWishlist.pending, (state) => {
        state.status.addWishlist = 'pending';
      })
      .addCase(addWishlist.fulfilled, (state, { payload }) => {
        state.status.addWishlist = 'succeeded';
      })
      .addCase(notifyMe.rejected, (state) => {
        state.status.notifyMe = 'error';
      })
      .addCase(notifyMe.pending, (state) => {
        state.status.notifyMe = 'pending';
      })
      .addCase(notifyMe.fulfilled, (state, { payload }) => {
        state.status.notifyMe = 'succeeded';
      })
      .addCase(getSimiliarProducts.rejected, (state) => {
        state.status.similiarProducts = 'error';
      })
      .addCase(getSimiliarProducts.pending, (state) => {
        state.status.similiarProducts = 'pending';
      })
      .addCase(getSimiliarProducts.fulfilled, (state, { payload }) => {
        state.similiarProducts = payload.data;
        state.status.similiarProducts = 'succeeded';
      })
      .addCase(reportReview.rejected, (state) => {
        state.status.reportReview = 'error';
      })
      .addCase(reportReview.pending, (state) => {
        state.status.reportReview = 'loading';
      })
      .addCase(reportReview.fulfilled, (state, { payload }) => {
        state.reportReview = payload.data;
        state.status.reportReview = 'succeeded';
      })
      .addCase(requestToDeleteReview.rejected, (state) => {
        state.status.requestToDeleteReview = 'error';
      })
      .addCase(requestToDeleteReview.pending, (state) => {
        state.status.requestToDeleteReview = 'loading';
      })
      .addCase(requestToDeleteReview.fulfilled, (state, { payload }) => {
        state.status.requestToDeleteReview = 'succeeded';
      })
      .addCase(getTotalUserPhotos.rejected, (state) => {
        state.status.totalUserPhotos = 'error';
      })
      .addCase(getTotalUserPhotos.pending, (state) => {
        state.status.totalUserPhotos = 'loading';
      })
      .addCase(getTotalUserPhotos.fulfilled, (state, { payload }) => {
        state.status.totalUserPhotos = 'succeeded';
        state.totalUserPhotos = payload.data;
      })
      .addCase(getProductDetail.rejected, (state) => {
        state.status.productDetailReview = 'failed';
      })
      .addCase(getProductDetail.pending, (state) => {
        state.status.productDetailReview = 'loading';
      })
      .addCase(getProductDetail.fulfilled, (state, { payload }) => {
        state.productDetailReview = payload.data;
        state.status.productDetailReview = 'succeeded';
      })
      .addCase(getEgiftTemplates.rejected, (state) => {
        state.status.messageTemplates = 'failed';
      })
      .addCase(getEgiftTemplates.pending, (state) => {
        state.status.messageTemplates = 'loading';
      })
      .addCase(getEgiftTemplates.fulfilled, (state, { payload }) => {
        state.egift.messageTemplates = payload.data;
        state.status.messageTemplates = 'succeeded';
      });
  },
});

export default reducer;

import { createSlice } from '@reduxjs/toolkit';
import { getPregnancyTimePeriod } from '~global/helpers';

const lilla_profiling = {
  children: [
    {
      phase_of_mother: '',
      prediction: { birth_date: '' },
      name: '',
      gender: '',
      date_of_birth: '',
      image: '',
      is_first_child: null,
    },
  ],
  is_lilla_profiling_completed: false, // complete profile
};
const initialState = {
  formIndex: 0,
  form: [
    {
      question: 'Di fase manakah saat ini kamu berada?',
      options: [
        { text: 'Mengandung', value: 'expecting' },
        { text: 'Menyusui', value: 'breastfeeding' },
        { text: 'Ibu batita', value: 'toddler' },
        { text: 'Ibu dengan Anak 3+ Tahun', value: 'three_year_kid' },
        { text: 'Belum menjadi Ibu', value: 'no' },
      ],
      answer: -1,
    },
    {
      question: 'Apakah ini pengalaman pertamamu menjadi ibu?',
      options: [
        { text: 'Ya', value: true },
        { text: 'Tidak', value: false },
      ],
      answer: -1,
    },
  ],
  // payload
  lilla_profiling,
};

export const { actions, reducer } = createSlice({
  name: 'profiling',
  initialState,
  reducers: {
    prev: (state) => {
      state.formIndex -= 1;
    },
    next: (state) => {
      state.formIndex += 1;
    },
    answer: (state, { payload }) => {
      state.form[state.formIndex].answer = payload;
    },
    resetLillaProfiling: (state) => {
      state.lilla_profiling = lilla_profiling;
    },
    setLillaProfiling: (state, { payload }) => {
      if (payload?.prediction?.birth_date?.length) {
        payload.prediction.pregnancy_time_period = getPregnancyTimePeriod(
          lilla_profiling.prediction.birth_date,
        );
      }
      state.lilla_profiling = Object.assign({}, state.lilla_profiling, payload);
    },
    setLillaProfilingChildren: (state, { payload }) => {
      state.lilla_profiling.children[0] = Object.assign(
        {},
        state.lilla_profiling.children[0],
        payload,
      );
    },
    setIsFirstChild: (state, { payload }) => {
      state.lilla_profiling.children[0] = Object.assign(
        {},
        state.lilla_profiling.children[0],
        { is_first_child: payload },
      );
    },
  },
});

export default reducer;

import { createSlice } from '@reduxjs/toolkit';
//
import { getBanners, getBannersAll } from '~store/promotion/thunks';

const locations = {
  partner_bank_promotion: [],
  sociolla_voucher_promotion: [],
};
const status = {
  banners: 'idle',
  bannersAll: 'idle',
};
export const { actions, reducer } = createSlice({
  name: 'promotion',
  initialState: {
    count: 0,
    locations,
    status,
  },
  reducers: {
    setState: (state, { payload: { key, value } }) => {
      state[key] = value;
    },
    setStatus: (state, { payload: { key, value } }) => {
      state.status[key] = value;
    },
    resetLocations: (state) => {
      state.locations = locations;
    },
    resetStatus: (state) => {
      state.status = status;
    },
  },
  extraReducers: {
    [getBanners.pending]: (state) => {
      state.status.banners = 'pending';
    },
    [getBanners.fulfilled]: (state, { payload }) => {
      state.locations.partner_bank_promotion =
        state.locations.partner_bank_promotion.concat(payload || []);
      state.status.banners = 'succeeded';
    },
    [getBannersAll.pending]: (state) => {
      state.status.bannersAll = 'pending';
    },
    [getBannersAll.fulfilled]: (state, { payload }) => {
      state.locations.sociolla_voucher_promotion =
        payload.sociolla_voucher_promotion || [];
      state.status.bannersAll = 'succeeded';
    },
  },
});

export default reducer;

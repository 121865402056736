import { createAsyncThunk } from '@reduxjs/toolkit';
//
import { Catalog } from '~api/catalog';

export const getBanners = createAsyncThunk(
  'promotion/getBanners',
  async ({ skip, limit }) =>
    await Catalog.bannersRequest({
      filter: {
        location: ['partner_bank_promotion'],
      },
      fields: 'title description images end_date more_info url',
      skip,
      limit,
    }).then(({ data: { data } }) => data),
);

export const getBannersAll = createAsyncThunk(
  'promotion/getBannersAll',
  async () =>
    await Catalog.bannersAllRequest({
      filter: { locations: ['sociolla_voucher_promotion'] },
      fields: 'title description images more_info url',
    }).then(({ data: { data } }) => data),
);

import { createSlice, createSelector } from '@reduxjs/toolkit';
import { isWeb } from '~global/screens';
const limitProduct = isWeb ? 20 : 10;
const initialState = {
  hasFiltered: false,
  onApply: false,
  onReset: false,
  brandsSelected: [],
  listBrandSelected: [],
  childAge: [],
  motherhoodStage: [],
  skinType: [],
  concern: [],
  product: [
    {
      name: 'All Product',
      value: 'all_product',
      code: 'all',
      isSelected: false,
    },
    {
      name: 'Bundles',
      value: 'is_bundle',
      code: 'bundles',
      isSelected: false,
    },
    {
      name: 'Pre-order',
      value: 'is_pre_order',
      code: 'pre',
      isSelected: false,
    },
  ],
  ratings: [
    { value: 5, isSelected: false },
    { value: 4, isSelected: false },
    { value: 3, isSelected: false },
    { value: 2, isSelected: false },
    { value: 1, isSelected: false },
  ],
  price: {
    min: 0,
    max: 1000000,
  },
  params: {
    sort: null,
    filter: {
      is_sale_lilla: null,
      'default_combination.price_after_discount': { $gte: 0, $lte: 1000000 },
      $and: [],
      'tags.name': { $in: [] },
      'brand.id': { $in: [] },
      'categories.id': { $in: null },
      is_bundle: null,
      is_pre_order: null,
    },
    skip: 0,
    limit: limitProduct,
  },
  page: 1,
  filtered: {
    brandsSelected: [],
    childAge: [],
    motherhoodStage: [],
    skinType: [],
    concern: [],
    product: [],
    ratings: [],
    selectedDisplayRatings: [],
    price: {
      min: 0,
      max: 1000000,
    },
  },
  common: {
    goToOtherFilter: false,
  },
  typeFormat: [],
  scents: [],
  size: [],
  material: [],
  flavours: [],
  gender: [],
};

const productFiltersSlice = createSlice({
  name: 'PRODUCT_FILTERS_V2',
  initialState,
  reducers: {
    SET_RATINGS(state, { data }) {
      state.ratings = data;
      state.selectedDisplayRatings = data;
    },
    SET_SECTION(state, { data }) {
      state.section = data;
    },
    SET_PARAMS(state, { name, value }) {
      state.params[name] = value;
    },
    SET_FILTERS(state, { name, value }) {
      state.params.filter[name] = value;
    },
    SET_MOTHERHOOD_STAGE(state, { data }) {
      state.motherhoodStage = data;
    },
    SET_CHILD_AGE(state, { data }) {
      state.childAge = data;
    },
    SET_SKIN_CONCERN(state, { data }) {
      state.skinType = data;
    },
    SET_CONCERN(state, { data }) {
      state.concern = data;
    },
    SET_TYPE_FORMAT(state, { data }) {
      state.typeFormat = data;
    },
    SET_SCENTS(state, { data }) {
      state.scents = data;
    },
    SET_SIZE(state, { data }) {
      state.size = data;
    },
    SET_MATERIAL(state, { data }) {
      state.material = data;
    },
    SET_FLAVOURS(state, { data }) {
      state.flavours = data;
    },
    SET_GENDER(state, { data }) {
      state.gender = data;
    },
    SET_PRODUCT(state, { data }) {
      state.product = data;
    },
    SET_PRICE(state, { data }) {
      state.price = data;
    },
    SET_STATE(state, { data, stateName }) {
      state[stateName] = data;
      if (stateName === 'onApply' && data === true) {
        state.filtered = {
          brandsSelected: state.brandsSelected,
          childAge: state.childAge.filter((x) => x.isSelected),
          motherhoodStage: state.motherhoodStage.filter((x) => x.isSelected),
          skinType: state.skinType.filter((x) => x.isSelected),
          concern: state.concern.filter((x) => x.isSelected),
          product: state.product.filter((x) => x.isSelected),
          ratings: state.ratings.filter((x) => x.isSelected),
          price: {
            min: state.params.filter[
              'default_combination.price_after_discount'
            ]['$gte'],
            max: state.params.filter[
              'default_combination.price_after_discount'
            ]['$lte'],
          },
          typeFormat: state.typeFormat.filter((x) => x.isSelected),
          scents: state.scents.filter((x) => x.isSelected),
          size: state.size.filter((x) => x.isSelected),
          material: state.material.filter((x) => x.isSelected),
          flavours: state.flavours.filter((x) => x.isSelected),
          gender: state.gender.filter((x) => x.isSelected),
        };
      }
    },
    RESET_PARAMS(state, { data }) {
      [
        'childAge',
        'motherhoodStage',
        'skinType',
        'ratings',
        'concern',
        'product',
        'typeFormat',
        'scents',
        'size',
        'material',
        'flavours',
        'gender',
      ].map((name) => {
        state[name].map((item, key) => {
          if (name !== 'product' && key !== 0) {
            state[name][key].isSelected = false;
          }
        });
      });
      state.price = {
        min: data?.price?.low ?? 0,
        max: data?.price?.high ?? 1000000,
      };
      state.params = {
        sort: null,
        filter: {
          is_sale_lilla: data?.isSale === true ? true : null,
          'default_combination.price_after_discount': {
            $gte: data?.price?.low ?? 0,
            $lte: data?.price?.high ?? 1000000,
          },
          $and: [],
          'tags.name': { $in: [] },
          'brand.id': { $in: [] },
          is_bundle: null,
          is_pre_order: null,
        },
        skip: 0,
        limit: limitProduct,
      };
      state.page = 1;
      state.hasFiltered = false;
      state.listBrandSelected = [];
      state.brandsSelected = [];
      state.filtered = {
        brandsSelected: [],
        childAge: [],
        motherhoodStage: [],
        skinType: [],
        concern: [],
        product: [],
        ratings: [],
        price: {
          min: 0,
          max: 1000000,
        },
        typeFormat: [],
        scents: [],
        size: [],
        material: [],
        flavours: [],
        gender: [],
      };
      state.product.map((item, key) => {
        if (item.value === data?.product) {
          state.product[key].isSelected = true;
        } else {
          state.product[key].isSelected = false;
        }
      });
      if (Array.isArray(data?.ratings)) {
        state.ratings.map((item, key) => {
          if (data.ratings.includes(item.value)) {
            state.ratings[key].isSelected = true;
          } else {
            state.ratings[key].isSelected = false;
          }
        });
      } else {
        state.ratings.map((item, key) => {
          if (item.value === data?.ratings) {
            state.ratings[key].isSelected = true;
          } else {
            state.ratings[key].isSelected = false;
          }
        });
      }
      state.concern.map((item, key) => {
        if (item.value === data?.concern) {
          state.concern[key].isSelected = true;
        } else {
          state.concern[key].isSelected = false;
        }
      });
    },
    SET_COMMON(state, { name, value }) {
      state[name] = value;
    },
    setLimit(state, { value }) {
      state.params.limit = value;
    },
    setSkip(state, { value }) {
      state.params.skip = value;
    },
    setPage(state, { value }) {
      state.page = value;
    },
  },
});

export const filterChanged = createSelector(
  (state) => state.newProductFilters,
  (params) => {
    if (params.ratings.filter((x) => x.isSelected).length) {
      return true;
    }
    if (
      params.filtered.brandsSelected.length !== params.brandsSelected.length
    ) {
      return true;
    }
    if (
      params.filtered.price.min !==
      params.params.filter['default_combination.price_after_discount']['$gte']
    ) {
      return true;
    }
    if (
      params.filtered.price.max !==
      params.params.filter['default_combination.price_after_discount']['$lte']
    ) {
      return true;
    }
    if (
      params.filtered.childAge.length !==
        params.childAge.filter((x) => x.isSelected).length ||
      params.filtered.childAge.some((x) => {
        const selectedData = params.childAge.find(
          (y) => y.isSelected && y.name === x.name,
        );
        return !selectedData || !selectedData.isSelected;
      })
    ) {
      return true;
    }
    if (
      params.filtered.motherhoodStage.length !==
        params.motherhoodStage.filter((x) => x.isSelected).length ||
      params.filtered.motherhoodStage.some((x) => {
        const selectedData = params.motherhoodStage.find(
          (y) => y.isSelected && y.name === x.name,
        );
        return !selectedData || !selectedData.isSelected;
      })
    ) {
      return true;
    }
    if (
      params.filtered.skinType.length !==
        params.skinType.filter((x) => x.isSelected).length ||
      params.filtered.skinType.some((x) => {
        const selectedData = params.skinType.find(
          (y) => y.isSelected && y.name === x.name,
        );
        return !selectedData || !selectedData.isSelected;
      })
    ) {
      return true;
    }
    if (
      params.filtered.concern.length !==
        params.concern.filter((x) => x.isSelected).length ||
      params.filtered.concern.some((x) => {
        const selectedData = params.concern.find(
          (y) => y.isSelected && y.name === x.name,
        );
        return !selectedData || !selectedData.isSelected;
      })
    ) {
      return true;
    }
    if (
      params?.filtered?.product.length !==
        params?.product.filter((x) => x.isSelected).length ||
      params?.filtered?.product.some((x) => {
        const selectedData = params.product.find(
          (y) => y.isSelected && y.name === x.name,
        );
        return !selectedData || !selectedData.isSelected;
      })
    ) {
      return true;
    }
    if (
      params?.filtered?.typeFormat?.length !==
        params?.typeFormat.filter((x) => x.isSelected).length ||
      params?.filtered?.typeFormat.some((x) => {
        const selectedData = params.typeFormat.find(
          (y) => y.isSelected && y.name === x.name,
        );
        return !selectedData || !selectedData.isSelected;
      })
    ) {
      return true;
    }
    if (
      params?.filtered?.scents?.length !==
        params.scents.filter((x) => x.isSelected).length ||
      params.filtered.scents.some((x) => {
        const selectedData = params.scents.find(
          (y) => y.isSelected && y.name === x.name,
        );
        return !selectedData || !selectedData.isSelected;
      })
    ) {
      return true;
    }
    if (
      params?.filtered?.size?.length !==
        params.size.filter((x) => x.isSelected).length ||
      params.filtered.size.some((x) => {
        const selectedData = params.size.find(
          (y) => y.isSelected && y.name === x.name,
        );
        return !selectedData || !selectedData.isSelected;
      })
    ) {
      return true;
    }
    if (
      params?.filtered?.material?.length !==
        params.material.filter((x) => x.isSelected).length ||
      params.filtered.material.some((x) => {
        const selectedData = params.material.find(
          (y) => y.isSelected && y.name === x.name,
        );
        return !selectedData || !selectedData.isSelected;
      })
    ) {
      return true;
    }
    if (
      params?.filtered?.flavours?.length !==
        params.flavours.filter((x) => x.isSelected).length ||
      params.filtered.flavours.some((x) => {
        const selectedData = params.flavours.find(
          (y) => y.isSelected && y.name === x.name,
        );
        return !selectedData || !selectedData.isSelected;
      })
    ) {
      return true;
    }
    if (
      params?.filtered?.gender?.length !==
        params.gender.filter((x) => x.isSelected).length ||
      params.filtered.gender.some((x) => {
        const selectedData = params.gender.find(
          (y) => y.isSelected && y.name === x.name,
        );
        return !selectedData || !selectedData.isSelected;
      })
    ) {
      return true;
    }
    return false;
  },
);

export const {
  SET_PARAMS,
  SET_SECTION,
  SET_FILTERS,
  SET_RATINGS,
  SET_MOTHERHOOD_STAGE,
} = productFiltersSlice.actions;
export default productFiltersSlice.reducer;

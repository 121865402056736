import { createSlice } from '@reduxjs/toolkit';
//
import { store } from '~store/pos/thunks';
import StoreList from '~global/class/StoreList';

const status = {
  store: 'idle',
};
export const { actions, reducer } = createSlice({
  name: 'pos',
  initialState: {
    store: new StoreList(),
    status,
    successfulRegister: false,
    selectedCountry: null,
  },
  reducers: {
    setStatus: (state, { payload: { key, value } }) => {
      state.status[key] = value;
    },
    resetStatus: (state) => {
      state.status = status;
    },
    setState(state, { name, value }) {
      state[name] = value;
    },
  },
  extraReducers: {
    [store.pending]: (state) => {
      state.status.store = 'pending';
    },
    [store.fulfilled]: (state, { payload: { data } }) => {
      if (data) {
        state.store = new StoreList(...data.data);
      }
      state.status.store = 'succeeded';
    },
  },
});

export default reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { Catalog } from '~api/catalog';
import { Cart } from '~api/cart';
import { Soco } from '~api/soco';
import { Analytics } from '~api/analytics';
import { InternalTracking } from '~global/sociolla.dm.lib';

export const getProduct = createAsyncThunk(
  'productDetail/getProduct',
  async (slug) => {
    const response = await Catalog.getProductRequest(slug);
    InternalTracking.viewContent(response.data.data);
    try {
      const combinations = response.data.data.combinations;
      const listGroupAttributeMapping = {};
      const listIdAttribute = {
        size: [],
        variant: [],
        shade: [],
      };

      if (
        Array.isArray(response.data.data.pack_detail) &&
        !response.data.data.pack_detail.length
      ) {
        combinations.map((combination, keyCombination) => {
          let nameListGroupAttribute = '';
          Object.entries(combination.attributes).map(
            ([attributeName, attribute], key) => {
              if (nameListGroupAttribute) {
                nameListGroupAttribute += '_';
              }
              if (
                attributeName !== 'non_specify' &&
                listIdAttribute[attributeName].indexOf(
                  attribute.my_soco_sql_id,
                ) < 0
              ) {
                listIdAttribute[attributeName].push(attribute.my_soco_sql_id);
              }
              nameListGroupAttribute += attribute.my_soco_sql_id;
            },
          );

          listGroupAttributeMapping[nameListGroupAttribute] = combination;
        });

        response.data.data.listGroupAttribute = listGroupAttributeMapping;
      }
    } catch (err) {
      console.log(err);
    }
    return response.data;
  },
);

export const getVouchersProduct = createAsyncThunk(
  'productDetail/getVouchersProduct',
  async (productId) => {
    const response = await Cart.getVouchersProduct(productId);
    return response.data;
  },
);

export const getUserPhotos = createAsyncThunk(
  'productDetail/getUserPhotos',
  async ({ id, limit }) => {
    const response = await Soco.getUserPhotos({ id, limit });
    return response.data;
  },
);

export const getPeopleAlsoBuyProducts = createAsyncThunk(
  'productDetail/getPeopleAlsoBuyProducts',
  async (productId) => {
    const response = await Catalog.getPeopleAlsoBuyProducts(productId);
    return response.data;
  },
);

export const getTrendingNowProducts = createAsyncThunk(
  'productDetail/getTrendingNowProducts',
  async () => {
    const response = await Catalog.getTrendingNowProducts();
    return response.data;
  },
);

export const getProductReviews = createAsyncThunk(
  'productDetail/getProductReviews',
  async (params) => {
    const response = await Soco.getProductReviews(params);
    return response.data;
  },
);

export const loadMoreProductReviews = createAsyncThunk(
  'productDetail/loadMoreProductReviews',
  async (params) => {
    const response = await Soco.getProductReviews(params);
    return response.data;
  },
);

export const getTotalReviews = createAsyncThunk(
  'productDetail/getTotalReviews',
  async (params) => {
    const response = await Soco.getTotalReviews(params);
    return response.data;
  },
);

export const getTotalVerifiedReviews = createAsyncThunk(
  'productDetail/getTotalVerifiedReviews',
  async (params) => {
    const response = await Soco.getTotalReviews(params);
    return response.data;
  },
);

export const getTotalReviewsWithPhoto = createAsyncThunk(
  'productDetail/getTotalReviewsWithPhoto',
  async (params) => {
    const response = await Soco.getTotalReviews(params);
    return response.data;
  },
);

export const reviewLike = createAsyncThunk(
  'productDetail/reviewLike',
  async (id) => {
    const response = await Soco.reviewLike(id);
    return response.data;
  },
);

export const addWishlist = createAsyncThunk(
  'productDetail/addWishlist',
  async (payload) => {
    const response = await Catalog.addWishlist(payload);
    const responseData = response?.data?.data ? response.data.data : {};
    InternalTracking.addToWishlist({ ...payload, ...responseData });
    return response.data;
  },
);

export const notifyMe = createAsyncThunk(
  'productDetail/notifyMe',
  async (payload) => {
    const response = await Catalog.notifyMe(payload);
    return response.data;
  },
);

export const getSimiliarProducts = createAsyncThunk(
  'productDetail/getSimiliarProducts',
  async (params) => {
    const response = await Analytics.getSimiliarProducts(params);
    return response.data;
  },
);

export const getRecentlyViewed = createAsyncThunk(
  'productDetail/getRecentlyViewed',
  async (params) => {
    const response = await Catalog.productViewsRequest(params);
    return response.data;
  },
);

export const reportReview = createAsyncThunk(
  'productDetail/reportReview',
  async ({ id, payload }) => {
    const response = await Soco.reportReview({ id, payload });
    return response.data;
  },
);

export const requestToDeleteReview = createAsyncThunk(
  'productDetail/requestToDeleteReview',
  async (id) => {
    const response = await Soco.requestToDeleteReview(id);
    return response.data;
  },
);

export const getTotalUserPhotos = createAsyncThunk(
  'productDetail/getTotalUserPhotos',
  async (id) => {
    const response = await Soco.getTotalUserPhotos(id);
    return response.data;
  },
);

export const getProductDetail = createAsyncThunk(
  'productDetail/getProductDetail',
  async (productId) => {
    const response = await Soco.getProductDetail(productId);
    return response.data;
  },
);

export const getEgiftTemplates = createAsyncThunk(
  'productDetail/getEgiftTemplates',
  async () => {
    const response = await Catalog.getEgiftTemplates();
    return response.data;
  },
);
